<template>
    <div class="form">
        <div class="form__row">
            <div class="form__label">
                Новый тип: 
            </div>
            <div class="form__input">
                <input type="text" v-model="name">
            </div>
            <div class="form__input">
                <button @click="btnHandler">Сохранить</button>
            </div>
            <div class="form__input">
                <router-link to="/packs/types"><button>Отмена</button></router-link>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import Alert from '../../plugins/alert'
export default {
    name: 'PacksTypesNew',
    data(){
        return {
            name: null,
        }
    },
    methods: {
        ...mapActions({
            createType: 'PackTypeCreate',
        }),
        btnHandler(){
            const payload = {
                name: this.name
            }
            this.createType(payload);
        }
    }
}
</script>